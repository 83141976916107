// clearfix...
.group:after {
  content: "";
  display: table;
  clear: both;
}

.half {
  width: 50%;

  &.pad {
    &:first-child {
      width: calc(50% - 30px);
      padding-right: 30px;
    }

    &:last-child {
      width: calc(50% - 30px);
      padding-left: 30px;
    }
  }
}

.quarter {
  width: 25%;
}

.three-quarters {
  width: 75%;
}

.third {
  width: 33.333%;
}

.two-thirds {
  width: 66.666%;

  &.no-float {
    float: none;
  }
}

.half, .quarter, .three-quarters, .third, .two-thirds {
  float: left;

  @media screen and (max-width: $width-md) {
    float: none;
    width: 100%;

    &.pad {
      &:first-child,
      &:last-child {
        width: 100%;
        padding: 0;
      }
    }
  }
}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder {
  color: $black;
}

/* Firefox 19+ */
::-moz-placeholder {
  color: $black;
}

/* IE 10+ */
:-ms-input-placeholder {
  color: $black;
}

/* Firefox 18- */
:-moz-placeholder {
  color: $black;
}

select::-ms-expand {
    display: none;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
