/* ----- VARS ----- */

$black: #0B0B0B;
$light-black: #181818;
$light-gray: #E5E5E5;
$medium-gray: #6A6A6A;
$gray: #666666;
$dark-gray: #373737;
$white: #FBFBFB;

$green: #ABF1AB;
$green-deep: #123109;
$green-light: #D6DCD6;
$green-mid: #19D319;
$green-dark: #00B800;

$blue: #9393FF;
$blue-muted: #EFEFFA;
$blue-deep: #040435;
$blue-dark: #0101A7;
$blue-light: #4141F9;
$blue-bright: #1515F2;
$blue-mid: #0B0BD9;

$cyan: #70c7c1;

// todo: get a proper error color
$red: #ff0000;

$font-sans: 'Diatype Medium', sans-serif;
$font-sans-light: 'Diatype Regular', sans-serif;
$font-serif: 'Chapeau Light', serif;
$font-mono: 'Diatype Mono Light';
$font-mono-medium: 'Diatype Mono Medium';
$font-bold: 'Diatype Bold';

$light: 300;
$reg: 400;
$med: 500;
$semi: 600;
$bold: 700;
$extbold: 800;

$width-lg: 1440px;
$width-md: 960px;
$width-sm: 600px;

$work-list-width: 279px;