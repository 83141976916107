/* ----- FONTS ----- */

// wide book
@font-face {
	font-family: 'Sohne Wide Book';
  src: url('../assets/fonts/soehne-breit-buch.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// wide semi-bold
@font-face {
	font-family: 'Sohne Wide Semi-Bold';
  src: url('../assets/fonts/soehne-breit-halbfett.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// wide bold
@font-face {
	font-family: 'Sohne Wide Bold';
  src: url('../assets/fonts/soehne-breit-kraftig.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// wide light
@font-face {
	font-family: 'Sohne Wide Light';
  src: url('../assets/fonts/soehne-breit-leicht.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// book
@font-face {
	font-family: 'Sohne Book';
  src: url('../assets/fonts/soehne-buch.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// light
@font-face {
	font-family: 'Sohne Light';
  src: url('../assets/fonts/soehne-leicht.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// monospace book
@font-face {
	font-family: 'Sohne Mono Book';
  src: url('../assets/fonts/soehne-mono-buch.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

// monospace bold
@font-face {
	font-family: 'Sohne Mono Bold';
  src: url('../assets/fonts/soehne-mono-kraftig.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}



@font-face {
	font-family: 'Chapeau Light';
  src: url('../assets/fonts/ChapeauWEB-Light.woff') format('woff'),
  	url('../assets/fonts/ChapeauWEB-Light.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Diatype Mono Light';
	src: url('../assets/fonts/ABCDiatypeSemi-Mono-Light.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}


@font-face {
	font-family: 'Diatype Mono Medium';
	src: url('../assets/fonts/ABCDiatypeSemi-Mono-Medium.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}


@font-face {
	font-family: 'Diatype Regular';
	src: url('../assets/fonts/ABCDiatype-Regular.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Diatype Medium';
	src: url('../assets/fonts/ABCDiatype-Medium.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Diatype Bold';
	src: url('../assets/fonts/ABCDiatype-Bold.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}