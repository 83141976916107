@import '_vars.scss';
@import '_fonts.scss';
@import '_common.scss';
@import '_mixins.scss';

html, body, root {
	max-width: 100vw;
	min-height: 100vh;
	background-color: $white;
	font-family: $font-sans;
	font-size: 18px;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
}

#content {
	flex: auto;
}

.frame {
	position: relative;
	margin: 0 auto;
	padding: 0 162px;
	max-width: 1116px;
	z-index: 100;

	@media screen and (max-width: $width-md) {
		padding: 0 24px;
	}
}

h1, h2, h3, h4 {
	font-weight: $light;
}

h2, h4 {
	font-family: $font-bold;
}

h2, .heading-0 {
	font-family: $font-bold;
	font-size: 18px;
	line-height: 29px;
	margin-bottom: 24px;
}

.heading-1 {
	font-family: $font-bold;
	font-size: 24px;
	line-height: 34px;
}

.heading-2 {
	font-family: $font-bold;
	font-size: 30px;
	line-height: 42px;
}

.heading-3 {
	font-family: $font-bold;
	font-size: 40px;
	line-height: 56px;
}

.heading-3a {
	font-family: $font-serif;
	font-size: 40px;
	line-height: 56px;
	margin: 96px 0;
}

.row {
	margin: 50px 0;

	&.field {
		margin: 15px 0;
		font-size: 14px;

		button {
			color: $white;
		}
	}
}

.action {
	font-family: $font-bold;
	font-size: 24px;
	line-height: 38px;
}

.dot {
	&::before {
		content: '•';
		font-size: 30px;
		margin-left: -20px;
		padding-right: 8px;
		color: $blue-light;
	}
}

.flex {
	display: flex;
}

ul {
	li {
		margin: 6px 0;
	}
}

a {
	font-family: $font-bold;
	font-size: 14px;
}

.hidden {
	//visibility: hidden;
	opacity: 0;
}

.fade-in {
	animation-name: fade-in;
	animation-fill-mode: both;
	animation-duration: 1.5s;
	margin: 0;
	padding: 0;
}

@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}


button {
	border-radius: 25px;

	cursor: pointer;
	font-family: $font-bold;
	text-align: center;
	text-decoration: none;
	font-size: 15px;
	min-width: 100px;
	line-height: 30px;
	padding: 2px 15px 0 15px;
	margin: 0;
	border: none;
	white-space: nowrap;
	transition: background-color .5s;
	text-transform: uppercase;
	font-weight: 700;
	height: 40px;
	color: $dark-gray;

	&:hover {
		background-color: $dark-gray;
		color: $white;
	}

	@media screen and (max-width: $width-sm) {
		min-width: 65px;
		height: 27px;
		font-size: 12px;
		line-height: 17px;
	}
}



.circle {
	overflow: hidden;
	border-radius: 50%;
	z-index: 10;
}

/* ----- FORM ELEMENTS ----- */

input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='tel'],
input[type='date'],
textarea,
select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

	font-family: 'Sohne Book';
	font-size: 14px;
	color: $black;
  border: 1px solid $gray;
  border-radius: 3px;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  width: calc(100% - 28px);
  background-color: $white;

  &:focus, &.is-active {
    border-color: $blue-bright;
  }

  &.error {
    border-color: $red;
    color: $red;
  }
}

.field-error {
	color: $red;
}

textarea {
	height: auto;
	resize: vertical;
}







header {
	padding: 58px 5%;

	.fixed-header {
		background-color: $light-gray;
		position: fixed;
		top: -100%;
		right: 0;
		left: 0;
		z-index: 200;
		padding: 58px 5% 15px 5%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: all 0.5s;
		&.show {
			top: 0;
			right: 0;
			left: 0;
		}
		&.inverse {
			background-color: $light-black;
		}
	}

	.main-header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&.inverse {
		background-color: $light-black;
	}

	.logo-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	h1 {
		margin: 0;
		width: 205px;
		height: 52px;
		background: url('../assets/images/logo-header-new.svg') center no-repeat;
		background-size: contain;
		font-size: 1px;
		text-indent: -999em;
		overflow: hidden;
		cursor: pointer;
		&.inverse {
			background: url('../assets/images/logo-header-inverted.svg') center no-repeat;
			background-size: contain;
		}
	}

	nav {
		margin-left: auto;
		display: flex;
		justify-content: space-around;
		width: 33%;
		@media screen and (max-width: $width-md) {
			width: 55%;
		}
		@media screen and (max-width: $width-sm) {
			justify-content: flex-end;
		}
		.nav-button {
			padding: 0;
			width: 58px;
			height: 16px;
			border-top: 3px solid black;
			border-bottom: 3px solid black;
			cursor: pointer;
			&.inverse {
				border-color: $white;
			}
			@media screen and (max-width: $width-sm) {
				width: 29px;
				height: 8px;
				border-top: 2px solid black;
				border-bottom: 2px solid black;
			}
		}

		a {
			color: $gray;
			text-decoration: none;
			padding: 10px;
			font-size: 15px;
			&:hover {
				color: $dark-gray;
			}
			&.inverse:hover {
				color: $white;
			}
		}
	}

	#side-panel {
		position: fixed;
		display: flex;
		flex-direction: column;
		height: 100vh;
		right: 0;
		z-index: 1000;
		top: -150%;
		width: 100%;
		background-color: $cyan;
		color: $dark-gray;
		transition: all 0.25s;
		&.open {
			top: 0;
			right: 0;
		}

		.close-row {
			display: flex;
			justify-content: flex-end;
			padding: 75px 5% 40px 5%;
			img {
				height: 29px;
				cursor: pointer;
			}
		}

		.panel-links {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			a {
				font-family: $font-mono;
				text-decoration: none;
				font-style: normal;
				font-weight: 300;
				font-size: 30px;
				line-height: 80px;
				color: $dark-gray;
				&:hover {
					color: $white;
				}
			}
		}
	}

}

footer {
	padding: 100px 0;
	bottom: 0;
	.footer-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: $width-sm) {
			flex-direction: column;
			gap: 20px;
		}
	}
	&.inverse {
		background-color: $light-black;
	}

	nav {
		display: flex;
		justify-content: space-between;
		width: 60%;

		a {
			font-style: normal;
			font-size: 15px;
			line-height: 28px;
			text-align: center;
			text-decoration: none;
			color: $gray;
			padding: 10px 0;
			&:hover {
				color: $dark-gray;
			}
			&.inverse:hover {
				color: $white;
			}
		}
		@media screen and (max-width: $width-sm) {
			width: 100%;
			flex-direction: column;
			gap: 25px;
		}
	}

	.copyright-phone-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 60%;
		margin: 30px auto 0px;
		@media screen and (max-width: $width-sm) {
			width: 100%;
			flex-direction: column;
			gap: 15px;
		}
	}

	.copyright {
		text-align: center;
		color: $gray;
		font-family: $font-bold;
		font-size: 12px;
		line-height: 17px;
	}
	.phone a {
		text-align: center;
		color: $gray;
		font-family: $font-bold;
		font-size: 12px;
		line-height: 17px;
		text-decoration: none;
	}

	@media screen and (max-width: $width-sm) {
		padding: 50px 0;
	}
}

.title {
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	font-size: 28px;
	line-height: 39px;
}

section {
	position: relative;
	padding: 100px 0;

	h3 {
		font-family: $font-serif;
		font-size: 68px;
		line-height: 95px;
	}

	h4 {
		font-size: 40px;
		line-height: 56px;
	}

	@media screen and (max-width: $width-sm) {
		padding: 36px 0;
	}
}



#hero {
	font-family: $font-mono;
	color: $dark-gray;
	font-weight: 300;
	font-size: 75px;
	text-align: center;
	line-height: 100.04px;
	@media screen and (max-width: $width-sm) {
		font-size: 45px;
		line-height: 70px;
	}
	.copy-wrapper {
		max-width: 100%;
		display: flex;
		gap: 30px;
		flex-wrap: wrap;
		justify-content: center;
		@media screen and (max-width: $width-md) {

			#for {
				width: 100%;

				//left: 0;
				//padding-left: 33%;
			}
		}
		@media screen and (max-width: $width-sm) {
			font-size: 30px;
			line-height: 37px;
		}
	}

	.rolling-word {
		@media screen and (max-width: $width-sm) {
			height: 75px;
		}
	}
}

#news-bar {
	background-color: $white;
	font-size: 14px;
	line-height: 22px;

	.news-item-container {
		height: 70px;
	}
	
	.fade-out {
		opacity: 0;
		height: 0;
		transition: height 0.5s 0.5s, opacity 0.5s;
	}

	.news-item {
		line-height: 70px;
	}

	p {
		display: inline-block;
		padding: 0 20px 0 0;
	}

	a {
		color: $green-deep;
		text-decoration: none;

		&:hover {
			color: $blue-dark;
		}
	}
}




#work {
	color: $dark-gray;
	padding: 100px 10%;
	.title {
		@media screen and (max-width: $width-sm) {
			font-size: 15px;
			line-height: 21px;
		}
	}

	.copy {
		padding: 40px 10% 160px 10%;
		font-family: $font-mono;
		font-style: normal;
		font-weight: 300;
		font-size: 45px;
		line-height: 56px;
		text-align: center;
		@media screen and (max-width: $width-sm) {
			font-size: 15px;
			line-height: 19px;
			padding-bottom: 57px;

		}
	}
	#workTypes {
		padding: 0;
	}
}

#clients {
	padding: 80px 5%;
	@media screen and (max-width: $width-sm) {
		padding: 69px 5%;
	}
	.clients-title {
		text-align: center;
		font-style: italic;
		font-weight: 550;
		font-size: 24px;
		line-height: 34px;
		text-align: center;
		color: $dark-gray;
		padding-bottom: 85px;
		@media screen and (max-width: $width-sm) {
			font-weight: 700;
			font-size: 12px;
			line-height: 17px;
			padding-bottom: 21px;
		}
	}
	.client-logo-wrapper {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		align-items: center;
		gap: 30px;
		@media screen and (max-width: $width-sm) {
			gap: 55px;
			flex-direction: column;
			img {
				max-width: 143px;
			}
		}
		// need media query below
		//img {
			//height: 70px
			//width: 100px;
		//}
	}
}


#scrollingLogo {
	.green-block {
		height: 100vh;
		background-color: #2C5E41;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;
		@media screen and (max-width: $width-sm) {
			// height: 226px;
		}
	}
	.circle {
		display: flex;
		position: absolute;
		z-index: 2;
		height: 447px;
		width: 447px;
		background-color: #70C7C3;

		.scroll-text {
			color: #2C5E41;
			align-self: center;
			margin-left: -810px;
			@media screen and (max-width: $width-sm) {
				margin-left: -212px;
			}
		}

		@media screen and (max-width: $width-sm) {
			height: 116px;
			width: 116px;
		}
	}

	.scroll-text {
		font-weight: 400;
		font-size: 111px;
		line-height: 137px;
		white-space:nowrap;
		text-transform: uppercase;
		color: $white;
		transition: transform 500ms ease-out;

		@media screen and (max-width: $width-sm) {
			font-weight: 500;
			font-size: 29px;
			line-height: 35px;
		}
	}
}

#about {
	.title {
		color: $dark-gray;
		@media screen and (max-width: $width-sm) {
			font-weight: 700;
			font-size: 15px;
			line-height: 21px;
		}
	}
	.copy {
		padding-top: 50px;
		font-family: $font-mono;
		font-weight: 300;
		font-size: 45px;
		line-height: 61px;
		text-align: center;
		color: $dark-gray;
		@media screen and (max-width: $width-sm) {
			padding-top: 18px;
			font-size: 15px;
			line-height: 19px;
		}
	}
}

#bios {
	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 170px;
		max-width: 100%;
		object-fit: contain;

		img {
			max-width: 100%;
		}
		@media screen and (max-width: $width-sm) {
			max-width: 75%;
			margin: auto;
			gap: 80px;
		}
	}
	.bio1-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 100px;
		object-fit: contain;
		@media screen and (max-width: $width-sm) {
			gap: 49px;
		}

		.bio-image {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
	.bio2-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		object-fit: contain;

		@media screen and (max-width: $width-sm) {
			justify-content: center;
			gap: 49px;
		}
	}
	.bio3-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 90px;
		@media screen and (max-width: $width-sm) {
			gap: 49px;
		}
	}
	.bio-details {
		display: flex;
		flex-direction: column;
		max-width: 520px;
		gap: 25px;
		@media screen and (max-width: $width-sm) {
			max-width: 90%;
			margin: auto;
		}
	}
	.bio-title {
		font-family: $font-mono-medium;
		font-weight: 400;
		font-size: 22px;
		line-height: 33px;
		color: $dark-gray;
		@media screen and (max-width: $width-sm) {
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
		}
	}

	.bio-copy {
		font-family: $font-mono;
		font-weight: 300;
		font-size: 18px;
		line-height: 30px;
		color: $dark-gray;
		@media screen and (max-width: $width-sm) {
			font-size: 13px;
			line-height: 18px;
		}
	}
}

#capabilities {
	padding-top: 190px;
	padding-bottom: 160px;
	@media screen and (max-width: $width-sm){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.title {
		color: $dark-gray;
		padding-bottom: 50px;
		font-weight: 700;

		@media screen and (max-width: $width-sm){
			font-size: 15px;
			line-height: 21px;
		}
	}
	.types-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 200px;
		@media screen and (max-width: 1050px){
			gap: 49px;
		}
		@media screen and (max-width: $width-sm){
			grid-template-columns: 1fr;
		}
	}
	.plus-icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 115px 0;
		@media screen and (max-width: $width-sm){
			padding: 40px 0;
			img {
				height: 13px;
				width: 13px;
			}
		}
	}
	.type-title {
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 39px;
		text-align: center;
		color: $dark-gray;
		padding-bottom: 26px;
		@media screen and (max-width: $width-sm){
			font-size: 15px;
			line-height: 21px;
			padding-bottom: 17px;
		}

	}
	.abilities {
		font-style: normal;
		font-family: $font-sans-light;
		font-weight: 400;
		font-size: 22px;
		line-height: 64px;
		text-align: center;
		color: $medium-gray;
		@media screen and (max-width: $width-sm){
			font-size: 13px;
			line-height: 33px;
		}
	}
}

#press, #press-news {
	.title {
		color: $dark-gray;
		@media screen and (min-width: $width-sm) {
			padding-bottom: 100px;
		}
 		@media screen and (max-width: $width-sm){
			font-weight: 700;
			font-size: 15px;
			line-height: 21px;
		}
	}

	.top-articles, .masked-articles {
		padding-top: 60px;
		display: flex;
		flex-direction: column;
		gap: 150px;
		@media screen and (min-width: $width-md) {
			position: relative;
			margin: 0 auto;
			padding: 0 162px;
			max-width: 1116px;
		}
		@media screen and (max-width: $width-sm) {
			gap: 40px;
			padding-top: 36px;
			padding-bottom: 25px;
		}
		.article {
			font-weight: 550;
			font-size: 47px;
			line-height: 66px;
			text-align: center;
			text-transform: uppercase;
			color: $dark-gray;
			&.white {
				color: $white;
			}
			@media screen and (max-width: $width-md) {
				font-size: 36px;
			}
			@media screen and (max-width: $width-sm) {
				max-width: 80%;
				margin: auto;
				font-size: 13px;
				line-height: 18px;
			}
		}
	}

	.top-articles {
		margin-top: -80px;
		@media screen and (max-width: $width-sm) {
			margin-top: -70px;
		}
	}

	.masked-articles {
		margin-top: 80px;
		@media screen and (max-width: $width-sm) {
			margin-top: -18px;
		}
	}

	.bottom-articles {
		max-width: 90%;
		margin: auto;
		@media screen and (min-width: $width-md) {
			position: relative;
			margin: 0 auto;
			padding: 0 162px;
			max-width: 1116px;
		}

		.article {
			display: grid;
			grid-template-columns: auto 100px;
			gap: 60px;
			padding: 60px 0;
			border-bottom: 1px solid $dark-gray;
			align-items: center;
			@media screen and (max-width: $width-sm) {
				padding: 22px 0;
				gap: 30px;
			}

			.article-title {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 20px;
				font-family: $font-sans-light;
				font-weight: 400;
				font-size: 22px;
				line-height: 31px;
				text-align: left;
				color: $medium-gray;
				@media screen and (max-width: $width-sm) {
					font-size: 13px;
					line-height: 18px;
				}
			}
		}
	}
}

.article-image {
	width: 100px;
	height: 100px;

	@media screen and (max-width: $width-sm) {
		width: 50px;
		height: 50px;
	}
}

#press-news {
	padding-top: 0;
}

#blackBox {
	background-color: $dark-gray;
	height: 216px;
	width: 100%;
	position: sticky;
	top: calc(50vh - 108px);
	z-index: 1;
	overflow: hidden;

	@media screen and (max-width: $width-sm) {
		height: 50px;
		top: calc(50vh - 25px);
	}
}


#team {
	color: $blue;
	background-color: $blue-deep;

	.notable-clients {
		li {
			text-align: center;
			float: left;
			max-width: 16.666%;

			img {
				max-height: 80px;
			}
		}
	}
}





#careers {
	padding-top: 200px;
	h2 {
		color: $blue-light;
	}
	.title {
		color: $dark-gray;
		@media screen and (max-width: $width-sm) {
			font-weight: 700;
			font-size: 15px;
			line-height: 21px;
		}
	}
	button {
		text-transform: uppercase;
	}
	.copy {
		font-family: $font-mono;
		font-weight: 300;
		font-size: 45px;
		line-height: 61px;
		text-align: center;
		color: $dark-gray;
		padding-top: 40px;
		padding-bottom: 140px;
		@media screen and (max-width: $width-sm) {
			font-size: 15px;
			line-height: 19px;
			padding-top: 18px;
			padding-bottom: 55px;
		}
	}
	.positions-wrapper {
		display: flex;
		flex-direction: column;
		gap: 180px;
		@media screen and (max-width: $width-sm) {
			gap: 45px;
		}
	}
	.position-wrapper {
		@media screen and (max-width: $width-sm) {
			max-width: 55%;
			margin: auto;
		}
		.position-title {
			font-weight: 700;
			font-size: 24px;
			line-height: 33px;
			color: $dark-gray;
			padding-bottom: 28px;
			@media screen and (max-width: $width-sm) {
				font-size: 15px;
				line-height: 20px;
				padding-bottom: 18px;
			}
		}
		.position-details {
			display: flex;
			justify-content: space-between;
			gap: 240px;

			.position-description {
				font-family: $font-sans-light;
				font-weight: 400;
				font-size: 22px;
				line-height: 30px;
				color: $medium-gray;
				@media screen and (max-width: $width-sm) {
					font-size: 13px;
					line-height: 18px;
				}
			}
			@media screen and (max-width: $width-sm) {
				flex-direction: column;
				gap: 25px;
			}
		}
	}
	.view-all-button {
		padding-top: 105px;
		display: flex;
		justify-content: flex-end;
		button {
			min-width: 183px;
		}
		@media screen and (max-width: $width-sm) {
			padding-top: 30px;
			justify-content: center;
		}
	}
}

#connect {
	h2 {
		color: $blue-light;
	}
}

#workPage {
	background-color: $light-black;
	color: $white;
	.client-btn-group {
		padding-top: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 50px;
		flex-wrap: wrap;
		@media screen and (max-width: $width-md){
			gap: 20px;
		}
		button {
			&:hover {
				background-color: $cyan;
				color: $dark-gray;
			}
		}
		.active-client {
			background-color: $cyan;
		}
	}
	#client {
		padding-top: 60px;
		
		@media screen and (max-width: $width-sm) {
			padding-bottom: 100px;
		}
		
		.work-title, .work-subtitle {
			font-family: $font-mono;
			font-weight: 300;
			font-size: 45px;
			line-height: 56px;
			text-align: center;
			@media screen and (max-width: $width-sm) {
				font-size: 30px;
				line-height: 37px;
			}
		}
		.work-copy {
			font-family: $font-sans-light;
			padding-top: 80px;
			padding-bottom: 100px;
			max-width: 550px;
			margin: auto;
			font-style: normal;
			font-weight: 400;
			font-size: 22px;
			line-height: 30px;
			@media screen and (max-width: $width-sm) {
				font-size: 15px;
				line-height: 20px;
				padding-bottom: 40px;
			}
		}
		.task-wrapper {
			display: flex;
			justify-content: space-around;

			.task-group {
				display: flex;
				flex-direction: column;
				gap: 50px;
				max-width: 290px;
				@media screen and (max-width: $width-sm) {
					gap: 25px;
				}
				.task {
					font-weight: 400;
					font-size: 22px;
					line-height: 30px;
					@media screen and (max-width: $width-sm) {
						font-size: 15px;
					}
				}
			}
			@media screen and (max-width: $width-sm) {
				flex-direction: column;
				gap: 25px;
				align-items: center;
			}
		}
		.client-image-wrapper {
			padding-top: 100px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 100px;
			align-items: center;
			margin: auto;
			img {
				max-width: 100%;
			}
			@media screen and (max-width: $width-sm) {
				padding-top: 50px;
				gap: 40px;
			}
		}
		.double-single {
			@extend .client-image-wrapper;
			@media screen and (min-width: $width-md) {
				div:nth-child(1) {
					width: 40%;
				}
				div:nth-child(2) {
					width: 40%;
				}
			}
		}
		.single-double {
			@extend .client-image-wrapper;
			@media screen and (min-width: $width-md) {
				div:nth-child(1) {
					width: 60%;
				}
				div:nth-child(2) {
					width: 40%;
				}
				div:nth-child(3) {
					width: 40%;
				}
			}
		}
		.links-section-wrapper {
			display: flex;
			flex-direction: column;
			gap: 167px;
			.link-section-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 116px;
				.articles {
					width: 100%;
					margin: auto;
					@media screen and (min-width: $width-md) {
						position: relative;
						margin: 0 auto;
						max-width: 1116px;
					}
					.article {
						display: grid;
						grid-template-columns: auto 100px;
						gap: 60px;
						padding: 60px 0;
						border-bottom: 1px solid $white;
						align-items: center;
						@media screen and (max-width: $width-sm) {
							padding: 22px 0;
							gap: 30px;
						}
						.article-title {
							font-family: $font-sans-light;
							font-weight: 400;
							font-size: 22px;
							line-height: 31px;
							text-align: left;
							color: $white;
							@media screen and (max-width: $width-sm) {
								font-size: 13px;
								line-height: 18px;
							}
						}
						button {
							&:hover {
								background-color: $cyan;
								color: $dark-gray;
							}
						}
					}
				}
				img {
					max-width: 100%;
				}
			}
		}
	}
}
#workTypes {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 60px;
	padding: 0 10%;
	div {
		flex: 1;
	}

	button {
		&.inverse {
			&:hover {
				background-color: $cyan;
				color: $dark-gray;
			}
		}
	}

	.work-title {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		color: $dark-gray;
		height: 50px;
		max-width: $work-list-width;
		&.inverse {
			color: $white;
		}
		@media screen and (max-width: $width-sm) {
			margin: auto;
			font-size: 17px;
			line-height: 21px;
			height: 25px;
		}
	}

	.work-copy {
		font-family: $font-sans-light;
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height:  24px;
		color: $medium-gray;
		padding-top: 29px;
		padding-bottom: 70px;
		max-width: $work-list-width;
		&.inverse {
			color: #C4C4C4;
		}
		height: 100px;
		@media screen and (max-width: 1200px) {
			height: 170px;
		}
		@media screen and (max-width: $width-md) {
			height: 100px;
		}
		@media screen and (max-width: 750px) {
			height: 170px;
		}
		@media screen and (max-width: $width-sm) {
			margin: auto;
			font-size: 15px;
			line-height: 18px;
			height: auto;
			padding-bottom: 50px;
		}
	}

	.work-type-wrapper {
		&:hover {
			.work-image {
				border-radius: 50% / 40%;
			}
		}
	}

	.work-image-wrapper {
		height: 449px;
		padding-bottom: 54px;
	}

	.work-image {
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center;
		max-width: $work-list-width;
		height: 100%;
		-moz-transition: all 0.5s;
		-o-transition: all 0.5s;
		-webkit-transition: all 0.5s;
		transition: all 0.5s;
		@media screen and (max-width: $width-sm) {
			object-fit: contain;
		}
		// TODO: This is what we want if only transforms on image hover
		//&:hover {
		//	border-radius: 50% / 20%;
		//}
	}
	.e-commerce {
		background-image: url('../assets/images/e-commerce.png');
	}
	.product-development {
		background-image: url('../assets/images/product-development.png');
	}
	.cloud-infrastructure {
		background-image: url('../assets/images/cloud-infrastructure.png');
	}
	.marketing-branding {
		background-image: url('../assets/images/marketing-branding.png');
	}
	@media screen and (max-width: $width-md) {
		width: 70%;
		object-fit: contain;
		margin: auto;
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (max-width: $width-sm) {
		grid-template-columns: 1fr;
	}
}